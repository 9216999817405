<script setup>
import { useGamesStore } from '@/stores/games';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const games = useGamesStore()
const route = useRoute()
const { getProvidersByCategory , getCategory} = storeToRefs(games)

</script>

<template>
    <div>
        <h3 class="text-gray-300 font-bold text-xl py-2 px-4">{{getCategory(route.params.id).nm}} Providers </h3>
        <ul class="px-3 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4 mt-6 space-x-1">
            <li v-for="provider in getProvidersByCategory(route.params.id).sort((a, b) => a.ord - b.ord)" class="flex-1 px-2 bg-neutral-600/25 rounded-lg">
                <RouterLink :to="`/casino/provider/${provider._id}`" class="block w-[94px] ">
                    <img :src="provider.img" class="h-[56px] w-full" :alt="provider.nm">
                </RouterLink> 
            </li>
        </ul>
    </div>
</template>