import http from "@/composables/axios";
import { defineStore, storeToRefs } from "pinia";
import { useResponseMessageStore } from "./response_message";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "./auth";
import { openDB } from 'idb';

export const useGamesStore = defineStore('games', {
    state: () => ({
        casino: {
            categories: [],
            subcategories: [],
            providers: [],
            games: [],
        },
        categories: [],
        subcategories: [],
        games: [],
        meta: {
            page: 1,
        },
        game: undefined,
        url: false,
        iframe: false
    }),

    getters: {
        getCasino: state => state.casino,
        // getCategories: state => [{_id: 0, nm: "Home"}, ...state.casino.categories],
        getCategories: state => {
            const categories = [{ _id: 0, nm: "Home" }];
            const hasNewGames = state.casino.games.some(game => game.new === true);
            if (hasNewGames) {
              categories.push({ _id: -1, nm: "New" });
            }
            
            return [...categories, ...state.casino.categories];
        },
        getCategory: state => categoryId => {
            return state.casino.categories.find(category => category._id == categoryId);
          },
        getSubcategories: state => categoryId => state.casino.subcategories.filter(s => s.cid == categoryId),
        // getGames: state => (categoryId, subcategoryId) => state.casino.games.filter(
        //     g => g.cid == categoryId && 
        //     g.subcategories.includes(subcategoryId)
        // ),
        getGames: state => (categoryId, subcategoryId, selectedProviders = []) => {
            return state.casino.games.filter(g => {
                const categoryMatch = g.cid == categoryId;
                const subcategoryMatch = g.subcategories.includes(subcategoryId);
                const providerMatch = selectedProviders.length == 0 || selectedProviders.includes(g.pid);
                return categoryMatch && subcategoryMatch && providerMatch;
            });
        },
        getProvidersByCategory: state => categoryId => state.casino.providers.filter(p => p.cid == categoryId),
        getMeta: state => state.meta,
        getGame: state => state.game,
        isUrl: state => state.url,
        getNewGames: state => state.casino.games.filter(g => g.new == true),
        getRecentsGames: state => {
            if (state.casino.recent_games_fid && state.casino.recent_games_fid.length > 0) {
                return state.casino.games.filter(game => state.casino.recent_games_fid.includes(game.fid));
            }
            return [];
        }
    },

    actions: {
        async fetchAllCasinoGames() {
            localStorage.removeItem('casino')
            localStorage.removeItem('casinoVersion')
            // const version = localStorage.casinoVersion ?? ''
            await http.get(`03/all`).then(async res => {
                if (res.data.ok) {
                    this.casino = res.data.data
                }
                // this.casino = JSON.parse(localStorage.casino)
                // if (res.status != 204 && res.data.ok) {
                //     localStorage.setItem('casino', JSON.stringify(res.data.data))
                //     localStorage.setItem('casinoVersion', res.data.data.version)
                // }
            })
        },

        async fetchCategories() {
            const auth = useAuthStore()
            const { getUser } = storeToRefs(auth)
            await http.get('03/games/categories').then(res => {
                if (res.data.ok) {
                    this.categories = []
                    this.categories.push({_id: 0, nm: 'Home'})
                    for (let data of res.data.data) {
                        this.categories.push(data)
                    }
                }
            })
        },

        async searchGames(name) {
            return await http.get(`03/games/search?name=${name}`)
        },

        async fetchSubcategories(id) {
            this.games = []
            this.subcategories = []
            if (!id) return
            await http.get(`03/games/categories/${id}/subcategories`).then(res => {
                if (res.data.ok) {
                    this.subcategories = res.data.data
                }
            })
        },

        async fetchGames(categoryId, subcategoryId, more = false) {
            if (more) {
                this.meta.page++
            }
            await http.get(`03/games/categories/${categoryId}/subcategories/${subcategoryId}/resources?page=${this.meta.page}`).then(res => {
                if (res.data.ok) {
                    this.games = [...this.games, ...res.data.data.games]
                    this.meta = res.data.data.meta
                }
            })
        },

        async fetchMainGames() {
            return await http.get(`03/games/main`)
        },

        async fetchGamesByProvider(id, page = 1) {
            return await http.get(`03/games/providers/${id}/games?page=${page}`)
        },

        async openGame(id, payload) {
            return await http.post(`03/games/${id}/open`, payload).then(res => {
                if (res.data.ok) {
                    this.game = res.data.data

                    this.iframe = res.data.iframe
                    this.url = res.data.url
                }

                return res
            })
        }
    }
})
