<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  options: {
    type: [Array, Object],
    required: true,
  },
  textAttribute: {
    type: String,
    default: null,
  },
  valueAttribute: {
    type: String,
    default: null,
  },
  modelValue: {
    type: Array,
    default: () => []
  },
  placeholder:{
    type:String,
    default:''
  }
});

const emit = defineEmits(['update:modelValue']);

const isOpen = ref(false);

const dropdownRef = ref(null);

const proxyValue = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  }
});

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function isSelected(option) {
  const optionValue = props.valueAttribute ? option[props.valueAttribute] : option;
  return proxyValue.value.includes(optionValue);
}

function toggleSelection(option) {
  const optionValue = props.valueAttribute ? option[props.valueAttribute] : option;
  if (isSelected(option)) {
    proxyValue.value = proxyValue.value.filter(v => v !== optionValue);
  } else {
    proxyValue.value = [...proxyValue.value, optionValue];
  }
}

function optionText(option) {
  return props.textAttribute ? option[props.textAttribute] : option;
}




function handleClickOutside(e) {
  if (dropdownRef.value && !dropdownRef.value.contains(e.target)) {
    isOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});
onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <div ref="dropdownRef" class="relative inline-block w-full text-xs">
    <!-- Field that toggles the dropdown -->
    <div 
        class="border border-neutral-400 rounded cursor-pointer py-2 px-2 bg-transparent text-neutral-400 flex items-center justify-between gap-2 overflow-hidden"
        @click="toggleDropdown"
        >
        <div class="flex items-center gap-1 min-w-0 truncate">
            <span class="truncate">{{ placeholder }}</span>
            <span 
                v-if="proxyValue?.length" 
                class="px-1 text-[10px] min-w-[18px] text-center bg-[var(--color-primary1)] text-white rounded-full"
            >
                {{ proxyValue.length }}
            </span>
        </div>
        <svg 
            class="w-4 h-4 shrink-0 transform transition-transform duration-150" 
            :class="{ 'rotate-180': isOpen }" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
    </div>

    <!-- Dropdown list -->
    <div 
      v-if="isOpen" 
      class="absolute left-0 z-10 mt-1 w-full bg-white shadow-lg border border-gray-200 rounded max-h-60 overflow-auto"
    >
      <ul>
        <li 
          v-for="(option, index) in options" 
          :key="valueAttribute ? option[valueAttribute] : index"
          class="px-2 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          @click.stop="toggleSelection(option)"
        >
          <input 
            type="checkbox" 
            class="form-checkbox mr-1"
            :checked="isSelected(option)"
            @change.stop=""
          />
          <span class="uppercase text-[11px] w-full">{{ optionText(option) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
