<script setup>
import { useUsersStore } from '@/stores/users';
import UserDetailsForm from './Partials/UserDetailsForm.vue';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import UserPasswordForm from './Partials/UserPasswordForm.vue';
import UserCreditsForm from './Partials/UserCreditsForm.vue';
import UserRaport from './Partials/UserRaport.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const users = useUsersStore()
const route = useRoute()
const user = ref(undefined)
const success = ref('')
const error = ref('')

const update = async (form) => {
    await users.updateUserDetails(route.params.id, form).then(res => {
        if (res.status == 204) {
            success.value = 'createUser.updated'
        }
    })
}

const updatePassword = async (form) => {
    await users.updatePassword(route.params.id, form).then(res => {
        if (res.status == 204) {
            success.value = 'changePassword.updated'
        }
    }).catch(e => {
        error.value = 'createUser.' + e.response.data.message
    })
}

const fetchUser = async () => {
    await users.fetchUser(route.params.id).then(res => {
        if (res.data.ok) {
            user.value = res.data.data
        }
    })
}

onMounted(async () => {
    await fetchUser()
})
</script>

<template>
    <div>
        <div class="py-2 px-4 bg-emerald-200/25 text-emerald-400 text-xs font-semibold" v-if="success">
            {{ t(success) }}
        </div>
        <div class="py-2 px-4 bg-red-200/25 text-red-400 text-xs font-semibold" v-if="error">
            {{ t(error) }}
        </div>

        <template v-if="user && !route.hash">
            <UserDetailsForm
                :title="$t('app.23')"
                :data="user"
                @submitted="update"
            />
        </template>
        <template v-if="route.hash == '#raport'">
            <UserRaport :data="user"/>
        </template>
        <template v-if="route.hash == '#changePassword'">
            <UserPasswordForm 
                @submitted="updatePassword"
            />
        </template>

        <template v-if="route.hash == '#credits'">
            <UserCreditsForm />
        </template>
    </div>
</template>