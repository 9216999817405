<script setup>
import Dropdown from '@/components/Dropdown.vue'
import DropdownLink from '@/components/DropdownLink.vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia';
import { useGamesStore } from '@/stores/games';
import MemberDropdown from './MemberDropdown.vue';

const authStore = useAuthStore()
const games = useGamesStore()
const { getCredits, getUser } = storeToRefs(authStore)
const { getCategories, getNewGames } = storeToRefs(games)
</script>

<template>
    <header id="header" class="bg-header-mobile">
        <div class="grid grid-cols-3 py-3 px-4">
            <div>
                <div class="max-w-max rounded-2xl border border-white/25 px-2.5 py-1.5">
                    <div class="text-[11px] font-extrabold text-white">{{$t('header.1')}}</div>
                </div>
            </div>
            <div class="flex justify-center">
                <RouterLink to="/">
                    <div class="hm-Logo default" v-if="!getUser.logo" />
                    <img class="hm-Logo" v-else :src="getUser.logo" />
                </RouterLink>
            </div>

            <div class="flex justify-end">
                <MemberDropdown />
            </div>
        </div>

        <nav class="flex justify-between px-8">
            <RouterLink class="hm-Link py-3.5" to="/">{{$t('header.2')}}</RouterLink>
            <RouterLink class="hm-Link py-3.5" to="/in-play">{{$t('header.3')}}</RouterLink>
            <RouterLink class="hm-Link py-3.5" to="/my-bets">{{$t('header.4')}}</RouterLink>
            <RouterLink class="hm-Link py-3.5" to="/casino" v-if="getCategories.length>1">
                <div class="relative">
                    {{$t('header.5')}}
                    <img v-if="getNewGames.length" src="/sh.png" class="w-6 h-6 absolute right-[-16px] top-[-16px]"/> 
                </div>
            </RouterLink>
        </nav>
    </header>
</template>

<style scoped>
.hm-Padd {
    padding: 0 calc((100vw - 1450px)/2);
}

.hm-Link {
    @apply inline-flex items-center border-b-2 border-transparent text-white text-[13px] font-bold;
}
.hm-Link.router-link-exact-active {
    color: var(--hm-selected);
    border-color: var(--hm-selected);
}
</style>
