<script setup>
import { onMounted, ref } from 'vue';
import TextField from '@/components/Form/TextField.vue';
import { modelDateValue, toFixedTwo } from '@/helpers';
import { useUsersStore } from '@/stores/users';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { watch } from "vue"
const emit = defineEmits(['submitted'])

const auth = useAuthStore()
const users = useUsersStore()
const { getCurrency } = storeToRefs(auth)
const props = defineProps({
    data: {
        type: Object,
    }
})
const credits = ref([])
const withdraw = ref([])
const deposit  = ref([]) 
const games = ref([])
const sports = ref([])
const lc = ref([])

const filters = ref({
    sd: modelDateValue(),
    ed: modelDateValue(),
})

const fetchUserRaport = async () => {
    await users.fetchUserRaport(filters.value , props.data._id).then(res => {
        if(res.data && res.data.ok){
            credits.value = res.data.data.credits
            withdraw.value = res.data.data.withdraw
            deposit.value = res.data.data.deposit
            games.value = res.data.data.games
            sports.value = res.data.data.sports
            lc.value = res.data.data.lc
        }
    })
}

watch(() => [props.data , filters.value.sd, filters.value.ed], async () => {
    await fetchUserRaport()
})

</script>

<template>
    <div class="px-6 py-4">
        <h1 class="text-base font-bold text-neutral-200">{{$t('reports.1')}} {{ data?.un }}</h1>
        <form class="pt-2">
            <TextField type="date" v-model="filters.sd" placeholder="Start Date" class="w-full rounded-b-none" />
            <TextField type="date" v-model="filters.ed" placeholder="End Date" class="w-full border-t border-neutral-200 rounded-none" />
        </form>
        <div class="mt-2">
            <h3 class="text-xs font-bold text-white/60 mb-2">{{$t('reports.4')}}</h3>
            <div class="fin-Container">
                <table class="fin-Table">
                    <thead>
                        <tr>
                        <th class="left">{{$t('reports.8') || 'Currency'}}</th>
                        <th>{{$t('reports.2') || 'Deposit'}}</th>
                        <th>{{$t('reports.3') || 'Withdraw'}}</th>
                        <th>{{$t('reports.4') || 'Amount'}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="credit in credits" :key="credit._id">
                        <td class="left">{{ credit?.cnm }}</td>
                        <td class="">{{ toFixedTwo((deposit.find(d => d._id == credit.cuid) || { amount: 0 }).amount) }}</td>
                        <td>{{ toFixedTwo((withdraw.find(w => w._id == credit.cuid) || { amount: 0 }).amount) }}</td>
                        <td>{{ toFixedTwo(credit?.amount) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-2">
            <h3 class="text-xs font-bold text-white/60 mb-2">{{$t('app.5')}}</h3>
            <div class="fin-Container">
                <table class="fin-Table">
                    <thead>
                        <tr>
                            <th class="left">{{$t('reports.5')}}</th>
                            <th>{{$t('reports.6')}}</th>
                            <th>{{$t('reports.7')}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="sports.length">
                        <tr v-for="sport in sports">
                            <td class="left">{{ sport.totalAmount }} {{getCurrency(sport._id).cod}}</td>
                            <td>{{ toFixedTwo(sport.totalFinalProfit) }} {{getCurrency(sport._id).cod}}</td>
                            <td>{{ toFixedTwo(sport.diff) }} {{getCurrency(sport._id).cod}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-2">
            <h3 class="text-xs font-bold text-white/60 mb-2">{{$t('app.6')}}</h3>
            <div class="fin-Container">
                <table class="fin-Table">
                    <thead>
                        <tr>
                            <th class="left">{{$t('reports.5')}}</th>
                            <th>{{$t('reports.6')}}</th>
                            <th>{{$t('reports.7')}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="games.length">
                        <tr v-for="game in games">
                            <td class="left">{{ game.totalAmount }} {{getCurrency(game._id).cod}}</td>
                            <td>{{ toFixedTwo(game.totalFinalProfit) }} {{getCurrency(game._id).cod}}</td>
                            <td>{{ toFixedTwo(game.diff) }} {{getCurrency(game._id).cod}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<style>

.fin-Container {
    background-image: var(--mgc-bg);
    @apply rounded-md overflow-hidden;
}

.fin-Table {
    @apply w-full text-neutral-200 divide-y divide-neutral-500/30;
    font-size: 2.5vw;
    line-height: 7vw;
    text-align: right;
}
.fin-Table tbody{ @apply  divide-y divide-neutral-500/30;}
.fin-Table th,
.fin-Table td {
    @apply px-2
}

.fin-Table th {
    @apply font-normal text-neutral-400;
}

.fin-Table .left {
    @apply text-left;
}
</style>